import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

// Generic
import Button from '../../shared/Button/Button'

// Local
import HeaderNavItem from './HeaderNavItem'

const HeaderNav = (props) => {
  const { className, setNavIsOpen } = props

  const nav = useStaticQuery(graphql`
    query HeaderNavigation {
      items: prismicHeaderNavigation {
        data {
          cta_label {
            text
          }
          cta_link {
            ...Link
          }
          body {
            ... on PrismicHeaderNavigationBodyHeaderGroup {
              id
              primary {
                item_link {
                  ...Link
                }
                item_label {
                  text
                }
                sublink_spacing
                item_section
              }
              items {
                subitem_label {
                  text
                }
                subitem_link {
                  ...Link
                }
                subitem_description {
                  text
                }
                is_footer_item
              }
            }
          }
        }
      }
    }
  `)

  // Filter into different sections (primary = left, secondary = right)
  const primaryItems = nav.items.data.body.filter(item => item.primary.item_section === 'primary')
  const secondaryItems = nav.items.data.body.filter(item => item.primary.item_section === 'secondary')

  const defaultMobileSpacing = 'space-y-5 lg:space-y-0'
  const defaultListClassNames = 'lg:flex items-center lg:space-x-4'

  return (
    <nav className={classNames(`fixed lg:static lg:flex items-center justify-end w-full pt-16 lg:py-0 bg-primary lg:bg-transparent overflow-auto lg:overflow-visible c-header-nav`, defaultMobileSpacing, className)}>
      <div className='lg:ml-8 xl:ml-16'>
        <ul className={classNames(defaultListClassNames, defaultMobileSpacing)}>
          {primaryItems.map((navItem, navItemIndex) => {
            return (
              <HeaderNavItem
                key={navItemIndex}
                navItem={navItem}
                setNavIsOpen={setNavIsOpen}
              />
            )
          })}
        </ul>
      </div>
      <div className='lg:flex items-center lg:ml-auto pb-16 lg:pb-0'>
        <ul className={classNames(defaultListClassNames, defaultMobileSpacing)}>
          {secondaryItems.map((navItem, navItemIndex) => {
            return (
              <HeaderNavItem
                key={navItemIndex}
                navItem={navItem}
                setNavIsOpen={setNavIsOpen}
              />
            )
          })}
        </ul>
        {nav.items.data.cta_label.text && (
          <div className='fixed bottom-0 left-0 w-full lg:static lg:ml-5'>
            <Button
              {...nav.items.data.cta_link}
              color='primary'
              // max-width media query would be better here. Essentially we've
              // got a custom mobile style, then we're just re-adding the styles
              // for desktop
              className={classNames('flex justify-between bg-white text-primary border-white hover:bg-secondary-light hover:border-secondary-light hover:text-black active:bg-secondary-light active:border-secondary-light active:text-black focus:bg-secondary-light focus:border-secondary-light focus:text-black lg:bg-primary lg:text-white lg:border-primary lg:hover:bg-primary-hover lg:hover:border-primary-hover lg:hover:text-white lg:active:bg-secondary-light lg:active:border-secondary-light lg:active:text-white lg:focus:bg-primary lg:focus:border-primary-active lg:focus:text-white')}
              iconClassName='hidden:lg'
              onClick={() => setNavIsOpen(false)}
              icon
            >
              {nav.items.data.cta_label.text}
            </Button>
          </div>
        )}
      </div>
    </nav>
  )
}

HeaderNav.propTypes = {
  className: PropTypes.string,
  setNavIsOpen: PropTypes.func.isRequired
}

export default HeaderNav
