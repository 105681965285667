import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * A consistent wrapper to use sitewide
 * @param  {Object} props
 * @return {Element}
 */
const Container = (props) => {
  const { children, className, element: Element, width, hasPadding } = props

  return (
    <Element
      className={classNames(`mx-auto ${hasPadding ? 'px-4' : ''}`, className, {
        'max-w-7xl': width === 'default',
        'max-w-6xl': width === 'thin',
        'max-w-5xl': width === 'thinner',
        'max-w-4xl': width === 'thinnest'
      })}
    >
      {children}
    </Element>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  className: PropTypes.string,
  element: PropTypes.string,
  width: PropTypes.oneOf(['default', 'thin', 'thinner', 'thinnest']),
  hasPadding: PropTypes.bool
}

Container.defaultProps = {
  element: 'div',
  width: 'default',
  hasPadding: true
}

export default Container
