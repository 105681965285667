import React from 'react'
import PropTypes from 'prop-types'

// Icons
import Facebook from '../../../assets/img/social/facebook.svg'
import Instagram from '../../../assets/img/social/instagram.svg'
import Linkedin from '../../../assets/img/social/linkedin.svg'
import Youtube from '../../../assets/img/social/youtube.svg'
import Github from '../../../assets/img/social/github.svg'
import XSocial from '../../../assets/img/social/x.svg'

const FooterLinkLabel = (props) => {
  const { label, platform, type } = props

  const platformIcons = {
    facebook: <Facebook className='w-4 h-4 fill-current' />,
    instagram: <Instagram className='w-4 h-4 fill-current' />,
    linkedin: <Linkedin className='w-4 h-4 fill-current' />,
    youtube: <Youtube className='w-4 h-4 fill-current' />,
    github: <Github className='w-4 h-4 fill-current' />,
    twitter: <XSocial className='w-4 h-4 fill-current' />,
  }

  if (type === 'footer_social') {
    return (
      <>
        <span className='sr-only'>Follow on {platform}</span>
        {platformIcons[platform.toLowerCase()]}
      </>
    )
  }

  return label.text
}

FooterLinkLabel.propTypes = {
  label: PropTypes.object,
  platform: PropTypes.string,
  type: PropTypes.string.isRequired
}

export default FooterLinkLabel
