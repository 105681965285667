import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

// Icons
import LogoTextRed from '../../../assets/img/logo-text-red.svg'
import LogoTechEd from '../../../assets/img/logo-tech-ed.svg'

// Local
import HeaderNav from './HeaderNav'

const Header = (props) => {
  const [path, setPath] = useState('')
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [previousScrollTop, setPreviousScrollTop] = useState(0)

  useEffect(() => {
    setPath(window.location.pathname)
  }, [typeof window !== 'undefined' && window.location.pathname])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [])

  /**
   * Closes the (mobile) menu when the `escape` key is pressed
   * @param  {Object}
   * @return {Void}
   */
  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 27) {
      setNavIsOpen(false)
    }
  }, [])

  /**
   * Close the (mobile) menu when the logo is clicked/navigated
   * @return {Void}
   */
  const handleLogoClick = () => {
    setNavIsOpen(false)
  }

  /**
   * Toggle open/close of the mobile menu
   * @param  {Object} event
   * @return {Void}
   */
  const handleMenuToggle = (event) => {
    event.preventDefault()
    // If the nav is already open, we're closing it, so scroll to the previous
    // position before the nav was opened. We're using a `position: fixed`
    // element when the nav is open as it's a bit nicer to scroll inside
    if (navIsOpen) {
      window.scroll(0, previousScrollTop)
    } else {
      // If we're opening the nav, save the current scroll position, then go to
      // the top of the page. This is so we don't get a gap betweem the header
      // element and the nav items
      setPreviousScrollTop(document.documentElement.scrollTop)
      window.scroll(0, 0)
    }

    setNavIsOpen(!navIsOpen)
  }

  return (
    <>
      {navIsOpen && <Helmet bodyAttributes={{ class: 'overflow-hidden lg:overflow-visible mobile-nav-open' }} />}
      <header
        className={classNames('lg:pt-4 absolute top-0 left-0 w-full z-50', {
          'fixed lg:absolute bg-primary lg:bg-transparent': navIsOpen,
        })}
      >
        <div className='flex justify-between items-center max-w-7xl mx-auto pl-4 lg:px-4 relative'>
          <Link
            to='/'
            className='block w-48 lg:w-40 xl:w-64 flex-shrink-0'
            onClick={handleLogoClick}
            aria-label='Homepage'
          >
            <LogoTextRed
              className={classNames('fill-current', {
                'text-primary': !navIsOpen,
                'text-white lg:text-primary': navIsOpen,
              })}
            />
          </Link>
          <button
            className={classNames('lg:hidden w-16 h-16 relative outline-none', {
              'bg-primary': !navIsOpen,
              'bg-white': navIsOpen
            })}
            onClick={handleMenuToggle}
            aria-label='Toggle Navigation'
          >
            <div
              className={classNames('w-16 h-16 flex flex-col items-center justify-center space-y-1', {
                'opacity-0': navIsOpen
              })}
            >
              {[...Array(3)].map((line, lineIndex) => {
                return (
                  <span
                    key={lineIndex}
                    className='block w-6 border-b-3 border-white'
                  />
                )
              })}
            </div>
            {['-rotate-45', 'rotate-45'].map((lineClassName, lineClassNameIndex) => {
              return (
                <span
                  key={lineClassNameIndex}
                  className={classNames(`block w-6 border-b-3 border-primary absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`, lineClassName, {
                    'opacity-0 pointer-events-none': !navIsOpen
                  })}
                />
              )
            })}
          </button>
          <HeaderNav
            className={classNames({ hidden: !navIsOpen })}
            setNavIsOpen={setNavIsOpen}
          />
        </div>
      </header>
    </>
  )
}

export default Header
