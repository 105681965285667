import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import jump from 'jump.js'

// Local
import ButtonIcon from './ButtonIcon'

const Button = (props) => {
  const {
    children,
    className,
    color,
    disabled,
    element: Element,
    icon,
    iconClassName,
    iconDirection,
    isBroken,
    linkType,
    onClick,
    target,
    url: href,
    tabIndex,
    onkeydown
  } = props

  // Check if broken here, `null` values should be ignored as they can point
  // to external URLs
  if (isBroken) {
    console.error(`Link to ${href} is broken. Please fix in the CMS`)
    return null
    // throw new Error(`Link to ${href} is broken. Please fix in the CMS`)
  }

  const buttonAttributes = {
    className: classNames('c-button', {
      [`c-button--${color}`]: color,
      'c-button--icon': icon
    }, className),
    disabled,
    href,
    onClick,
    target
  }

  if (target === '_blank') {
    buttonAttributes.rel = 'noopener noreferrer'
  }

  // Is this a link to an internal document?
  if (href && Element === 'a' && linkType === 'Document') {
    // Rename `href` to `to`, as this is what is used by `<Link>`
    buttonAttributes.to = buttonAttributes.href
    delete buttonAttributes.href

    return (
      <Link
      tabIndex={tabIndex}
        {...buttonAttributes}
      >
        {(icon && iconDirection === 'left') && (
          <ButtonIcon
            direction={iconDirection}
            className={iconClassName}
          />
        )}
        {children}
        {(icon && iconDirection === 'right') && (
          <ButtonIcon
            direction={iconDirection}
            className={iconClassName}
          />
        )}
      </Link>
    )
  }

  // Linking to a non-Document in the CMS will force it start with `https://`
  // so check if it starts with `https://#`, then remove the `https://` part.
  // The rest is should be a hash which points to an ID on the same page, so
  // clicking the CTA should smooth scroll down the page to the element it
  // points to.
  if (!onClick && href && href.startsWith('https://#')) {
    // jumpTo: `#careers`
    const jumpTo = href.replace('https://', '')
    /**
     * Function to animate down the page if it's a hash link to an id on the
     * current page
     * @param  {Object} event
     * @return {Void}
     */
    buttonAttributes.onClick = (event) => {
      event.preventDefault()
      jump(jumpTo)
    }
  }

  return (
    <Element
      tabIndex={tabIndex}
      onkeydown={onkeydown}
      {...buttonAttributes}
    >
      {(icon && iconDirection === 'left') && (
        <ButtonIcon
          direction={iconDirection}
          className={iconClassName}
        />
      )}
      {children}
      {(icon && iconDirection === 'right') && (
        <ButtonIcon
          direction={iconDirection}
          className={iconClassName}
        />
      )}
    </Element>
  )
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'inline']).isRequired,
  disabled: PropTypes.bool,
  element: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.bool,
  iconClassName: PropTypes.string,
  iconDirection: PropTypes.string,
  isBroken: PropTypes.bool,
  linkType: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  url: PropTypes.string,
  tabIndex: PropTypes.number,
  onkeydown: PropTypes.func
}

Button.defaultProps = {
  element: 'a',
  iconDirection: 'right',
  onkeydown: () => {}
}

export default Button
