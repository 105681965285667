import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HubspotFormComponent from 'react-hubspot-form'

// Icons
import IconLoading from '../../../assets/img/icon-loading.svg'

const HubspotForm = (props) => {
  const { className, formId, redBgToggle } = props

  const formClass = classNames({
    'bg-tertiary-lighter p-6 text-midnight c-hubspot-form-dark': redBgToggle,
    'c-hubspot-form': !redBgToggle
  })

  const iconLoadingClass = classNames('text-midnight')

  return (
    <div className={classNames(className, formClass)}>
      <HubspotFormComponent
        portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
        formId={formId}
        loading={<div className='flex items-center'><IconLoading className={classNames('animate-spin h-5 w-5 mr-2', iconLoadingClass)} />Loading...</div>}
        cssRequired=''
      />
    </div>
  )
}

HubspotForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired
}

export default HubspotForm
