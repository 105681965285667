import React from 'react'
import { Helmet } from 'react-helmet'

// Import SCSS, which includes tailwind
import '../../../assets/scss/app.scss'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const App = ({ children, ...props }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'antialiased'
        }}
      />
      <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-MC36NJS'
          height='0' width='0' style={{
            display: 'none',
            visibility: 'hidden'
          }}
        />
      </noscript>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default App
